.order-title {
  margin-bottom: 2rem !important;
  text-align: center;
  width: 100%;
  text-decoration: underline;
}

span.title {
  font-weight: bold;
  margin-bottom: 10px;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

div.margin-right {
  padding: 0 250px 0 0;
}

td.border-top-bottom {
  font-weight: bold;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

td.firstCell {
  width: 27%;
}

div.cellWithSpan {
  width: 35%;
  text-align: right;
}

span.detail-label {
  width: 280px;
  float: left;
  font-weight: bold
}

div.detail-set {
  margin: 10px;
  width: 100%;
}

div.detail-set > div {
  display: flex;
  margin-bottom: 1.3em;
}

div.detail-set > div > div:first-child {
  font-weight: bold;
  width: 27%;
}

div.detail-value-list {
  display: flex;
  flex-direction: column;
}

table.order-detail {
  width: 100%;
  border-top: 1px dotted #000000;
  border-bottom: 1px solid #000000;
  padding-top: 10px;
  padding-bottom: 100px;
  background-color: #eee;
}