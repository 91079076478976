@import '../../../../styles/variables.scss';

.k-pager-numbers .k-link.k-state-selected {
  background-color: $primary;
  border-color: $primary;
}

.k-pager-nav.k-link,
.k-pager-refresh {
  color: $primary;
}

.k-pager-numbers .k-link {
  color: $primary;
}

.k-grid-toolbar {
  justify-content: flex-end;
}
