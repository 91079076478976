@import './variables.scss';
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.k-textbox {
  &:focus {
    border-color: #80be99;
    box-shadow: 0 0 0 0.25rem rgba(0, 125, 50, 0.25);
  }
}

.k-numerictextbox .k-numeric-wrap.k-state-focused {
  border-color: #80be99;
  box-shadow: 0 0 0 0.25rem rgba(0, 125, 50, 0.25);
}

.k-animation-container {
  z-index: 10003 !important;
}
