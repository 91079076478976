.margin-top {
  margin-top: 15px;
}

.form-1-box {
  text-align: left;
}

.form-1-box legend {
  font-size: 1rem;
  line-height: 30px;
  font-weight: 600;
  color: #555;
  width: auto;
  margin-left: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

#routed-content {
  margin-left: 20px;
  padding-left: 5px;
}
