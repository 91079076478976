@import '../../../styles/variables.scss';

.agr-global-filter .btn {
  background-color: white !important;
  color: inherit !important;
  border-width: 0px !important;
  box-shadow: none !important;
}

.agr-global-filter {
  .k-dropdown {
    width: 100%;
    padding: 3px;
    .k-dropdown-wrap {
      background-color: white;
      border-color: white;
      border-bottom-color: $primary;
      border-radius: 0%;

      .k-input {
        color: $primary;
      }
    }
  }
}

.k-dropdown {
  .k-dropdown-wrap {
    background-color: white;

    &:hover,
    &:focus {
      background-color: white;
    }
  }
}
