.react-time-picker__wrapper {
  border: 1px solid #ced4da;
  background-color: #f9f9f9;
}

.react-time-picker__inputGroup {
  padding: 0.375rem 0.75rem;
}

.react-time-picker__clock-button {
  pointer-events: none;
}

.DayPickerInput .form-control::placeholder {
  opacity: 0.8;
  color: rgb(170, 170, 170);
}

.text-list-item {
  line-height: 1.3;

  font-style: italic;
  font-size: 0.8em;
}
